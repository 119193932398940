import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-south-bend-indiana.png'
import image0 from "../../images/cities/scale-model-of-south-bend-visitor-information-center-in-south-bend-indiana.png"
import image1 from "../../images/cities/scale-model-of-studebaker-national-museum-in-south-bend-indiana.png"
import image2 from "../../images/cities/scale-model-of-the-oliver-mansion-in-south-bend-indiana.png"
import image3 from "../../images/cities/scale-model-of-basilica-of-the-sacred-heart-in-south-bend-indiana.png"
import image4 from "../../images/cities/scale-model-of-eck-visitors-center-in-south-bend-indiana.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='South Bend'
            state='Indiana'
            image={image}
            lat='41.6763545'
            lon='-86.25198979999999'
            attractions={ [{"name": "South Bend Visitor Information Center", "vicinity": "101 N Michigan St #300, South Bend", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.6765488, "lng": -86.25081990000001}, {"name": "Studebaker National Museum", "vicinity": "201 Chapin St, South Bend", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.67483800000001, "lng": -86.26216799999997}, {"name": "The Oliver Mansion", "vicinity": "808 W Washington St, South Bend", "types": ["point_of_interest", "establishment"], "lat": 41.6758043, "lng": -86.26206860000002}, {"name": "Basilica of the Sacred Heart", "vicinity": "101 Basilica Dr, Notre Dame", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 41.7026517, "lng": -86.23974620000001}, {"name": "Eck Visitors Center", "vicinity": "Eck Visitors Center, 100 Eck Center, Notre Dame", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.6965385, "lng": -86.23920980000003}] }
            attractionImages={ {"South Bend Visitor Information Center":image0,"Studebaker National Museum":image1,"The Oliver Mansion":image2,"Basilica of the Sacred Heart":image3,"Eck Visitors Center":image4,} }
       />);
  }
}